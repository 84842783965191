export default {
  pageTransitionTime : 1000,
  dragTransitionTime : 1000,
  colors             : {
    // black    : '#1C1C1A',
    // white    : '#FFFFFF',
    // offWhite : '#EEEBE6'
    black    : '#000000',
    white    : '#FFFFFF',
    offWhite : '#ffffff'
  }
}
